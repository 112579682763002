<!--
 * @Author: your name
 * @Date: 2021-03-24 16:14:08
 * @LastEditTime: 2021-03-26 18:04:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\download.vue
-->
<template>
  <div class="box">
    <div class="info">
      <li>{{ lang == "cnc" ? "Mini激光雕刻机" : "拾音助手" }}</li>
      <p>广州华汇信息科技有限公司</p>
      <a href="javascript:void(0)" @click="download"
        ><img src="../../assets/downloada.png"
      /></a>

      <div v-if="laq" class="tip">
        <span>检测到您是用微信浏览,请点击右上角使用默认浏览器打开.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",
  data() {
    return {
      deta: `${new Date().getFullYear()}年${new Date().getMonth() +
        1}月${new Date().getDate()}日`,

      lang: "",

      url: "",

      laq: false, // 微信浏览器
    };
  },
  watch: {
    lang(n) {
      this.url =
        n == "cnc"
          ? "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/LaserEngrave-V1.0.8_release_200713.apk"
          : "https://acc-huahui.oss-cn-shenzhen.aliyuncs.com/grave/BTMic_assistant.apk";
    },
  },
  mounted() {
    let { lang } = this.$route.params;
    this.lang = lang;
    switch (lang) {
      case "cnc":
        document.title = "Mini激光雕刻机APP下载";
        break;
      case "voice":
        document.title = "拾音助手APP下载";
        break;
      default:
        document.title = "APP下载";
    }

    // 浏览器类型
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("micromessenger") !== -1) {
      this.laq = true;
    }
  },
  methods: {
    download() {
      if (this.laq) {
        alert("请用默认浏览器打开");
      } else {
        window.open(this.url, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.box {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: url("../../assets/download.png") no-repeat top center;
  background-size: cover;
}
.info {
  position: absolute;
  top: 22%;
  left: 0;
  right: 0;
  text-align: center;
}
.info li {
  list-style: none;
  font-size: 56px;
  font-weight: 600;
  color: #ffe600;
  text-shadow: 0 0 1px #000;
}
.info p {
  margin: 50px 0 30px;
  font-size: 32px;
  text-shadow: 0 0 1px #000;
  color: #fff;
}
.info div {
  line-height: 50px;
  margin-bottom: 30px;
}
.info div span {
  display: inline-block;
  margin: 0 15px;
  color: #fff;
  font-size: 24px;
  text-shadow: 0 0 2px #000;
}
.info a {
  transition: all 0.3s;
}

.info a img {
  width: 300px;
  height: 96px;
}

.tip {
  margin-top: 50px;
}
.tip span {
  color: #fff;
  text-shadow: 0 0 2px #000;
}
</style>
